export default [
  {
    path: "/destaca-tu-finca/:slug",
    name: "stand-out",
    component: () =>
      import(
        /* webpackChunkName: "stand-out" */ "@/views/farm/standOutFarm/StandOutFarm"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finca/:slug",
    name: "FarmDetail",
    component: () =>
      import(
        /* webpackChunkName: "FarmDetail" */ "@/views/farm/farmsDetail/FarmDetail"
      ),
  },
  {
    path: "/lista-de-fincas",
    name: "ListOfFarms",
    component: () =>
      import(
        /* webpackChunkName: "ListOfFarms" */ "@/views/farm/listOfFarms/ListOfFarms"
      ),
  },
  {
    path: "/verificando-pago",
    name: "VerifyPayment",
    component: () =>
      import(
        /* webpackChunkName: "VerifyPayment" */ "@/views/farm/publishFarms/VerifyPayment"
      ),
  },
];
