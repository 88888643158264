<template>
  <div class="relative bg-white">
    <div class="px-4 sm:px-6">
      <div
        class="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <nav class="hidden space-x-10 md:flex">
          <router-link
            :to="{ name: 'HomeAuction' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Home Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'AuctionsDetail' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Detalle Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'ListOfAuctions' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Lista de Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'AuctionFilter' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Filtro de Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step1' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 1</span>
          </router-link>
          <router-link
            :to="{ name: 'Step2' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 2</span>
          </router-link>
          <router-link
            :to="{ name: 'Step3' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 3</span>
          </router-link>
          <router-link
            :to="{ name: 'Step4' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 4</span>
          </router-link>
          <router-link
            :to="{ name: 'Step5' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 5</span>
          </router-link>
          <router-link
            :to="{ name: 'Step6' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 6</span>
          </router-link>
          <router-link
            :to="{ name: 'Step7' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 7</span>
          </router-link>
          <router-link
            :to="{ name: 'Step8' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 8</span>
          </router-link>
          <router-link
            :to="{ name: 'Step9' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 9</span>
          </router-link>
          <router-link
            :to="{ name: 'Step10' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 10</span>
          </router-link>
        </nav>
      </div>
      <div
        class="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <nav class="hidden space-x-10 md:flex">
          <router-link
            :to="{ name: 'Step1GeneralAuctionRules' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 1</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2EnterDataNaturalPerson' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2EnterDataLegalPerson' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.1</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerification' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.2</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerificationDataIncorrect' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.3</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerificationSuccessful' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.4</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerificationRejected' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.5</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step3WithholdingTax' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 3</span
            >
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>
