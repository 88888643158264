<template>
  <section v-if="isLoad" class="containerGifLoading sizeMinAllView heigth__">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else>
    <PxHeader :activeSearchDesktop="false" />
    <publish-button />
    <div class="positionButtonOpportunityFarms absolute z-10">
      <button-opportunity-farms />
    </div>
  </section>
</template>
<script>
import moment from "moment";
import { generateHash } from "@/utils";
import { mapState, mapActions } from "vuex";
import algoliasearch from "algoliasearch/lite";
// mixins
import validationToken from "@/mixins/validationToken";
// components
import PxHeader from "@/components/header/PxHeader";

moment.locale("es");
export default {
  inject: ["provider"],
  mixins: [validationToken],
  name: "Home",
  components: {
    PxHeader,
  },
  data() {
    const algoliaId =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_ALGOLIA_ID_TEST
        : process.env.VUE_APP_ALGOLIA_ID;
    const algoliaPrivateKey =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_PRIVATE_KEY_ALGOLIA_TEST
        : process.env.VUE_APP_PRIVATE_KEY_ALGOLIA;
    return {
      selected: "",
      currentDay: null,
      isModal: false,
      isModalLead: false,
      /**
       *variable that stores the value of the image of the modal or video
       * @type {String}
       */
      url: null,
      /**
       *Allows access to all the methods that establish the connection to Axios for requests to the agrogo api
       * @type {Object}
       */
      repository: this.provider.homeRepository,
      searchClient: algoliasearch(algoliaId, algoliaPrivateKey),
      perPage: 4,
    };
  },
  computed: {
    ...mapState("preLaunch", ["isLoad"]),
    ...mapState("user", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("publishFarm", ["restoreData"]),
    /**
     * query agrogo api to get current server time
     * @returns {String} return the date
     */
    async timezone() {
      const me = this;
      try {
        let diff = 0;
        const { data } = await me.repository.timezone();
        me.currentDay = moment(data);
        if (localStorage.getItem("displayDate")) {
          const displayDate = moment(localStorage.getItem("displayDate"));
          diff = me.currentDay.diff(displayDate, "days");
        }
        return diff;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     *
     * method that obtains the image of the modal or video
     * @returns  {void}
     */
    async loadVideoORimage() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const name = "MODAL_MAIN";
        const {
          data: { data },
        } = await me.repository.find(
          {
            ...dataAditional,
            dev0: process.env.VUE_APP_DEBUG_CUSTOM,
          },
          name
        );
        const urlImg = `${process.env.VUE_APP_AWS_URL}${data.url_media}`;
        me.url = { ...data, urlImg };
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
    /**
     * saves in the local storage the value of displayleads that allows to visualize the modal next to the date of when the modal was opened
     * @returns {void}
     */
    async loadModal() {
      const me = this;
      const diff = await me.timezone();
      await me.loadVideoORimage();
      const display = localStorage.getItem("display");
      if (!display) {
        localStorage.setItem("displayDate", me.currentDay.format("YYYY-MM-DD"));
        localStorage.setItem("display", "one");
        me.isModal = true;
        return;
      }
      if (display == "one") {
        localStorage.setItem("display", "two");
        me.isModal = true;
      } else {
        if (diff >= 3) {
          localStorage.setItem(
            "displayDate",
            me.currentDay.format("YYYY-MM-DD")
          );
          localStorage.setItem("display", "one");
          me.isModal = true;
        }
      }
    },
    /**
     * saves in the local storage the value of displayleads that allows to visualize the modal.
     * @returns {void}
     */
    async loadModalLead() {
      const me = this;
      const display = localStorage.getItem("displayLeads");
      if (!display) {
        localStorage.setItem("displayLeads", "one");
        me.isModalLead = true;
        return;
      }
    },
    advancedSearch() {
      const me = this;
      me.$router.push("lista-de-fincas");
    },
    screenFarmSimilary() {
      const me = this;
      const width = screen.width;
      if (width >= 1200) {
        me.perPage = 4;
      } else if (width >= 768 && width <= 1200) {
        me.perPage = 3;
      } else {
        me.perPage = 2;
      }
    },
  },
  created() {
    const me = this;
    window.addEventListener("resize", function () {
      me.screenFarmSimilary();
    });
  },
  mounted() {
    const me = this;
    me.screenFarmSimilary();
    if (me.isAuthenticated) {
      me.loadModal();
    } else {
      me.loadModalLead();
    }
    me.restoreData();
  },
};
</script>
<style lang="css">
.heigth__ {
  height: 100vh !important;
}
.ais-Hits ol {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  place-content: center;
  place-items: center;
  gap: 0.5rem;
}
@media (min-width: 768px) {
  .ais-Hits ol {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }
}
@media (min-width: 1280px) {
  .ais-Hits ol {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
  }
}
.ais-InstantSearch {
  width: 100%;
  z-index: 5 !important;
}
.search-bar-border {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 0rem;
}
.icon-search-bar-boder {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 0rem;
}
/* .ais-SearchBox-input {
  background: red;
} */
</style>
