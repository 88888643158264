import { createRouter, createWebHistory } from "vue-router";
import configRoutes from "@/config/farmRoutes";
import Home from "@/views/farm/home/HomeSearch";
import store from "../store";

const routes = [
  // RUTAS GENERALES
  {
    path: "/",
    component: Home,
  },
  {
    path: "/inicio",
    name: "HomeFarm",
    component: Home,
  },
  {
    path: "/inicio-de-sesion",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/auth/Login"),
  },
  {
    path: "/registro",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/auth/Register"),
  },
  {
    path: "/verificar-numero-de-telefono",
    name: "VerifyCellPhone",
    component: () =>
      import(
        /* webpackChunkName: "VerifyCellPhone" */
        "@/views/auth/VerifyCellPhone"
      ),
  },
  {
    path: "/registro-exitoso",
    name: "SuccessfulRegistration",
    component: () =>
      import(
        /* webpackChunkName: "SuccessfulRegistration" */
        "@/views/auth/SuccessfulRegistration"
      ),
  },
  {
    path: "/recuperacion-de-contrasena",
    name: "PasswordRecovery",
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */
        "@/views/auth/PasswordRecovery"
      ),
  },
  {
    path: "/cambiar-de-contrasena",
    name: "PasswordRecovery2",
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */
        "@/views/auth/PasswordRecovery"
      ),
  },
  {
    path: "/mi-cuenta",
    name: "mi-cuenta",
    component: () =>
      import(/* webpackChunkName: "mi-cuenta" */ "@/views/profile/MyAccount"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-cuenta-persona-natural",
    name: "MyAccountNaturalPerson",
    component: () =>
      import(
        /* webpackChunkName: "miCuenta" */
        "@/views/profile/MyAccountNaturalPerson"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-cuenta-persona-juridica",
    name: "MyAccountLegalPerson",
    component: () =>
      import(
        /* webpackChunkName: "miCuenta" */
        "@/views/profile/MyAccountLegalPerson"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Fincas
  {
    path: "/publicar-finca",
    name: "publicar",
    component: () =>
      import(
        /* webpackChunkName: "publicar" */
        "@/views/farm/publishFarms/PublishFarm"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/completar-finca/:slug",
    name: "completr-publicar",
    component: () =>
      import(
        /* webpackChunkName: "completr-publicar" */
        "@/views/farm/publishFarms/PublishFarm"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mis-fincas",
    name: "MyFarms",
    component: () =>
      import(/* webpackChunkName: "MyFarms" */ "@/views/farm/myFarms/MyFarms"),
    meta: {
      requiresAuth: true,
    },
  },
  // Rutas footer
  {
    path: "/quienes-somos",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "AboutUs" */ "@/views/aboutUs/AboutUs"),
  },
  {
    path: "/contactanos",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ "@/views/contactUs/ContactUs"),
  },
  {
    path: "/politicas-de-privacidad",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */
        "@/views/privacyPolicy/PrivacyPolicy"
      ),
  },
  {
    path: "/preguntas-frecuentes",
    name: "FrequentlyAskedQuestions",
    component: () =>
      import(
        /* webpackChunkName: "FrequentlyAskedQuestion" */
        "@/views/frequentlyAskedQuestions/FrequentlyAskedQuestions"
      ),
  },
  //Error
  {
    path: "/:pathMatch(.*)",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error"*/ "@/views/error404/Error404"),
  },
  //Landing
  {
    path: "/landing",
    name: "landing",
    component: () =>
      import(/* webpackChunkName: "landing"*/ "@/components/landing/Landing"),
  },
  // RUTAS PARA SUBASTAS
  {
    path: "/inicio-subastas",
    name: "HomeAuction",
    component: () =>
      import(/* webpackChunkName: "HomeAuction" */ "@/views/auction/home/Home"),
  },
  {
    path: "/detalle-subastas",
    name: "AuctionsDetail",
    component: () =>
      import(
        /* webpackChunkName: "AuctionsDetail" */
        "@/views/auction/auctionsDetail/AuctionsDetail"
      ),
  },
  {
    path: "/lista-de-subastas",
    name: "ListOfAuctions",
    component: () =>
      import(
        /* webpackChunkName: "ListOfAuctions" */
        "@/views/auction/listOfAuctions/ListOfAuctions"
      ),
  },

  {
    path: "/filtro-subastas",
    name: "AuctionFilter",
    component: () =>
      import(
        /* webpackChunkName: "AuctionFilter" */
        "@/views/auction/auctionFilter/AuctionFilter"
      ),
  },
  {
    path: "/publicar-subasta-paso-1",
    name: "Step1",
    component: () =>
      import(
        /* webpackChunkName: "Step1" */
        "@/views/auction/publishAuctions/components/step1/Step1"
      ),
  },
  {
    path: "/publicar-subasta-paso-2",
    name: "Step2",
    component: () =>
      import(
        /* webpackChunkName: "Step2" */
        "@/views/auction/publishAuctions/components/step2/Step2"
      ),
  },
  {
    path: "/publicar-subasta-paso-3",
    name: "Step3",
    component: () =>
      import(
        /* webpackChunkName: "Step3" */
        "@/views/auction/publishAuctions/components/step3/Step3"
      ),
  },
  {
    path: "/publicar-subasta-paso-4",
    name: "Step4",
    component: () =>
      import(
        /* webpackChunkName: "Step4" */
        "@/views/auction/publishAuctions/components/step4/Step4"
      ),
  },
  {
    path: "/publicar-subasta-paso-5",
    name: "Step5",
    component: () =>
      import(
        /* webpackChunkName: "Step5" */
        "@/views/auction/publishAuctions/components/step5/Step5"
      ),
  },
  {
    path: "/publicar-subasta-paso-6",
    name: "Step6",
    component: () =>
      import(
        /* webpackChunkName: "Step6" */
        "@/views/auction/publishAuctions/components/step6/Step6"
      ),
  },
  {
    path: "/publicar-subasta-paso-7",
    name: "Step7",
    component: () =>
      import(
        /* webpackChunkName: "Step7" */
        "@/views/auction/publishAuctions/components/step7/Step7"
      ),
  },
  {
    path: "/publicar-subasta-paso-8",
    name: "Step8",
    component: () =>
      import(
        /* webpackChunkName: "Step8" */
        "@/views/auction/publishAuctions/components/step8/Step8"
      ),
  },
  {
    path: "/publicar-subasta-paso-9",
    name: "Step9",
    component: () =>
      import(
        /* webpackChunkName: "Step9" */
        "@/views/auction/publishAuctions/components/step9/Step9"
      ),
  },
  {
    path: "/publicar-subasta-paso-10",
    name: "Step10",
    component: () =>
      import(
        /* webpackChunkName: "Step10" */
        "@/views/auction/publishAuctions/components/step10/Step10"
      ),
  },
  //Error
  {
    path: "/:pathMatch(.*)",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error"*/ "@/views/error404/Error404"),
  },
  {
    path: "/inscribir-subastas-paso-1",
    name: "Step1GeneralAuctionRules",
    component: () =>
      import(
        /* webpackChunkName: "Step1GeneralAuctionRules" */
        "@/views/auction/register/components/Step1GeneralAuctionRules"
      ),
  },
  {
    path: "/inscribir-subastas-paso-2",
    name: "Step2EnterDataNaturalPerson",
    component: () =>
      import(
        /* webpackChunkName: "Step2EnterDataNaturalPerson" */
        "@/views/auction/register/components/Step2EnterDataNaturalPerson"
      ),
  },
  {
    path: "/inscribir-subastas-paso-2.1",
    name: "Step2EnterDataLegalPerson",
    component: () =>
      import(
        /* webpackChunkName: "Step2EnterDataLegalPerson" */
        "@/views/auction/register/components/Step2EnterDataLegalPerson"
      ),
  },
  {
    path: "/inscribir-subastas-paso-2.2",
    name: "Step2DataVerification",
    component: () =>
      import(
        /* webpackChunkName: "Step2DataVerification" */
        "@/views/auction/register/components/Step2DataVerification"
      ),
  },
  {
    path: "/inscribir-subastas-paso-2.3",
    name: "Step2DataVerificationDataIncorrect",
    component: () =>
      import(
        /* webpackChunkName: "Step2DataVerificationDataIncorrect" */
        "@/views/auction/register/components/Step2DataVerificationDataIncorrect"
      ),
  },
  {
    path: "/inscribir-subastas-paso-2.4",
    name: "Step2DataVerificationSuccessful",
    component: () =>
      import(
        /* webpackChunkName: "Step2DataVerificationSuccessful" */
        "@/views/auction/register/components/Step2DataVerificationSuccessful"
      ),
  },
  {
    path: "/inscribir-subastas-paso-2.5",
    name: "Step2DataVerificationRejected",
    component: () =>
      import(
        /* webpackChunkName: "Step2DataVerificationRejected" */
        "@/views/auction/register/components/Step2DataVerificationRejected"
      ),
  },
  {
    path: "/inscribir-subastas-paso-3",
    name: "Step3WithholdingTax",
    component: () =>
      import(
        /* webpackChunkName: "Step3WithholdingTax" */
        "@/views/auction/register/components/Step3WithholdingTax"
      ),
  },
];

if (!store.state.preLaunch.isPreLaunch) {
  let routes2 = null;
  routes2 = configRoutes["launch"].routes;
  routes2.forEach((route) => {
    routes.push(route);
  });
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export async function beforeEach(to, from, next) {
  if (!store.state.preLaunch.isPreLaunch) {
    let routes2 = null;
    routes2 = configRoutes["launch"].routes;
    routes2.forEach((route) => {
      routes.push(route);
    });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
      return;
    }
    next("/inicio-de-sesion");
  } else {
    if (
      localStorage.getItem("authenticated") === "true" &&
      to.name === "Login"
    ) {
      const isPre = localStorage.getItem("isPreLaunch");
      const path = isPre == "true" ? "/publicar-finca" : "/inicio";
      next(path);
    } else {
      next();
    }
  }
}
router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
