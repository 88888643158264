import AuthRepository from "@/repositories/AuthRepository";
import store from "../store";
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY_AUTH;
const PUBLIC_KEY = process.env.VUE_APP_PUBLIC_KEY_AUTH;

export const generateHash = async () => {
  const { data } = await AuthRepository.timezone();
  // console.log(data);
  // Validar prelanzamiento
  store.dispatch("preLaunch/loadisLoad", false);
  store.dispatch("preLaunch/loadLaunch", data.status);
  localStorage.setItem("isPreLaunch", data.status);
  const HORA_ACTUAL = data.timezone;
  const signature = PRIVATE_KEY + "," + PUBLIC_KEY + "," + HORA_ACTUAL;

  const encoder = new TextEncoder();
  const signature2 = encoder.encode(signature);

  const hashBuffer = await window.crypto.subtle.digest("SHA-256", signature2);

  const hashArray = Array.from(new Uint8Array(hashBuffer));

  const hash256 = hashArray
    .map((b) => ("00" + b.toString(16)).slice(-2))
    .join("");

  const data1 = {
    /*Produccion*/
    apiKey: PUBLIC_KEY,
    utcTimeStamp: HORA_ACTUAL,
    signature: hash256,
    /*Developer*/
    // dev0: 5.118,
  };

  return data1;
};
